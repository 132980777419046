.wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  :global {
    .ant-table-wrapper {
      display: flex;
      max-height: 100%;
      overflow: auto;
      overflow-x: hidden;
    }

    .ant-spin-nested-loading {
      width: 100%;
    }
  }
}
