.header {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;

  .close {
    margin-left: auto;
    cursor: pointer;
    display: grid;
    border: none;
    background: transparent;
  }
}

.from {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin: 12px 0;
  .fromItem {
    display: grid;
    gap: 4px;
  }
}

.body {
  display: grid;
  gap: 12px;
  margin: 16px 0;
}

.buttons {
  padding-top: 8px;
  display: flex;
  gap: 8px;
  width: 100%;
}
