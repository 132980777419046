.logoGsp5 {
  height: 32px;
  color: var(--color-primary-6);
  position: absolute;
  bottom: 4%;
  left: 50%;
  transform: translateX(-50%) translateY(-4%);
}

.logoBlue {
  height: 40px;
  position: absolute;
  top: 8%;
  left: 50%;
  transform: translateX(-50%) translateY(-8%);
}

.loaderWrapper {
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  top: 48%;
  transform: translateY(-48%);
}

iframe {
  display: none;
}

a {
  cursor: pointer;
  color: var(--color-primary-6);

  &:hover {
    color: var(--color-primary-5);
  }
}

:global {
  .ant-table-column-title {
    font-size: 12px !important;
    font-weight: 500 !important;
  }

  .ant-table-wrapper .ant-table-container table > thead > tr:first-child > *:first-child {
    border-start-start-radius: 0 !important;
  }

  .ant-table-empty {
    overflow: hidden;

    .ant-table-body {
      overflow: hidden !important;
    }
  }

  .ant-form-item-has-error {
    .ql-container {
      border: 1px solid var(--color-error) !important;
    }
  }

  .ant-tree {
    .ant-tree-treenode {
      &:has(.ant-tree-indent-unit) {
        border-left: 2px solid var(--color-primary-6) !important;
        background: var(--color-neutral-2) !important;
      }
    }

    .ant-tree-checkbox {
      margin-top: 6px !important;
    }

    .ant-tree-node-content-wrapper {
      padding-top: 1px !important;
    }

    .ant-tree-title {
      color: var(--color-character-title-85) !important;
      font-size: 16px !important;
      font-weight: 500 !important;
      line-height: 22px !important;
      text-align: left !important;
    }

    .ant-tree-switcher {
      justify-content: center;
    }
  }

  .ant-input-data-count {
    top: -22px !important;
  }

  .ant-form-item-label {
    > label {
      font-weight: 500 !important;
      font-size: 16px !important;
      line-height: 22px !important;
    }
  }

  .ant-tabs > .ant-tabs-nav {
    padding-left: 16px !important;

    .ant-tabs-nav-list {
      .ant-tabs-tab-btn {
        font-weight: 500 !important;
        transition: none !important;

        &:active {
          color: var(--color-neutral-2) !important;
        }
      }

      .ant-tabs-tab {
        border-color: var(--color-neutral-2) !important;

        &:hover {
          background: var(--color-primary-5) !important;
        }
      }

      .ant-tabs-tab-active {
        background: var(--color-primary-6) !important;
      }
    }
  }

  .ant-select-arrow {
    color: var(--color-primary-6) !important;
  }

  .ant-input-prefix {
    color: var(--color-character-secondary-45) !important;
  }

  .ant-input-affix-wrapper > input.ant-input {
    color: var(--color-character-title-85) !important;
  }

  .ant-select-selector {
    .ant-select-selection-overflow-item {
      .ant-select-selection-item {
        padding-left: 10px;
        padding-right: 10px;
        background: var(--color-primary-6) !important;
        border-radius: 20px;
        color: var(--color-neutral-1) !important;

        svg {
          color: var(--color-neutral-1);
        }
      }
    }
  }
}

::-webkit-scrollbar-thumb {
  background-color: var(--color-primary-6);
  border-radius: 5px;

  &:hover {
    background-color: var(--color-primary-5);
  }
}

::-webkit-scrollbar {
  background: var(--color-neutral-2);
  width: 6px;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}
